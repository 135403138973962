import { gql } from '@apollo/client';
// Fragments
import { MediaImageFields, MediaVideoFields } from './fragments';

// GetMediaPost_getMediaPost_VideoPost_mentions

export const GET_MEDIA_POST = gql`
  query GetMediaPost($input: GetMediaPostInput!) {
    getMediaPost(input: $input) {
      body
      createdAt
      id
      slug
      status
      scheduledAt
      scheduledAtOffset
      scheduledAtTzCode
      buyProductLink
      mentions {
        id
        mentionType
        store {
          slug
          storeDetails {
            storeId
            avatarURL
            storeName
          }
        }
        targetType
        unregisteredContact {
          id
          name
          representativeName
          phoneNumber
          type
          email
          socialMediaLink
          name
          representativeName
          url
        }
      }
      hashtags {
        id
        isGeneral
        name
      }
      store {
        id
        slug
        lastName
        firstName
        role
        amas {
          id
          type
          price
          videoURL
          videoThumbnailURL
          status
        }
        status
        nextStream {
          id
          slug
          description
          name
          scheduleDate
          timeZone {
            tzCode
            offset
          }
          repeatsEveryEnum
          isPurchased
          streamStatus
          imageURL
          isFree
          price
          store {
            id
            slug
            storeDetails {
              id
              storeName
              storeTitle
              description
              bio
              avatarURL
              coverURL
              socialMediaLink
              gender
              athleticLevel
              careerStatus
            }
          }
        }
        hashtags {
          id
          name
        }
        hasMerch
        hasAma
        hasMediaPost
        hasProducts
        hasMemorabilia
        hasPureProducts
        hasExperiences
        hasStreams
        hasActiveStreams
        hasArticle
        storeDetails {
          storeName
          avatarURL
          description
          bio
        }
      }
      title
      ... on ImagePost {
        image {
          ...MediaImageFields
        }
      }
      ... on VideoPost {
        thumbnailKey
        thumbnailUrl
        video {
          ...MediaVideoFields
        }
      }
    }
  }
  ${MediaImageFields}
  ${MediaVideoFields}
`;

export const GET_MEDIA_POSTS = gql`
  query GetMediaPosts($input: GetMediaPostsInput!) {
    getMediaPosts(input: $input) {
      total
      offset
      limit
      entities {
        body
        createdAt
        id
        slug
        status
        scheduledAt
        scheduledAtOffset
        scheduledAtTzCode
        buyProductLink
        store {
          id
          slug
          lastName
          firstName
          role
          sports {
            id
            name
          }
          storeDetails {
            id
            storeName
            storeTitle
            avatarURL
          }
        }
        title
        ... on ImagePost {
          image {
            ...MediaImageFields
          }
        }
        ... on VideoPost {
          thumbnailKey
          thumbnailUrl
          video {
            ...MediaVideoFields
          }
        }
      }
    }
  }
  ${MediaImageFields}
  ${MediaVideoFields}
`;

export const GET_VIDEO_REQUESTS_FOR_STORE = gql`
  query GetVideoEditRequestsForStore(
    $input: VideoEditRequestsStoreFilterInput!
  ) {
    getVideoEditRequestsForStore(input: $input) {
      entities {
        createdAt
        status
        id
        storeId
        assignedEditorId
        editedVideos {
          title
          body
          id
          videoEditRequestId
          mentions {
            id
            targetType
            mentionType
            store {
              slug
              id
              storeDetails {
                storeName
                storeId
                avatarURL
              }
            }
            unregisteredContact {
              id
              email
              representativeName
              phoneNumber
              createdAt
              socialMediaLink
              name
              url
              type
            }
          }
          hashtags {
            id
            name
          }
          key
          videoUrl
          thumbnailUrl
          reviewSubmissions {
            id
            status
          }
        }
      }
      limit
      offset
      total
    }
  }
`;
